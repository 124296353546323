import React from 'react'
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import Layout from '../../components/Layout'

class Election2019Page extends React.Component {
  render() {
    const payasyougo = this.props.data.payasyougo
    const voterspass = this.props.data.voterspass
    const communitythree = this.props.data.communitythree
    const savethedate = this.props.data.savethedate
    return (
  <Layout>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-1">2019 Bond Election</h1>
              <p>See documents and articles related to the 2019 bond election.</p>
              <hr />
              <div className="news-preview-container">

                <div className="news-preview-item">
                  <Link to="/2019-bond-election/2019-12-22-westlake-mud-1-voters-pass-bond-authorization-in-november-2019-election">
                    <Img fluid={voterspass.fluid} alt="" style={{ height: '154px', borderTopLeftRadius: '2px', borderTopRightRadius: '2px' }} />
                  </Link>
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <Link to="/2019-bond-election/2019-12-22-westlake-mud-1-voters-pass-bond-authorization-in-november-2019-election">Funding District Rehabilitation: Selling Bonds vs. Direct Billing</Link>
                    <br /><small className="accent-color">December 22, 2019</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <Link className="button is-white" to="/2019-bond-election/2019-12-22-westlake-mud-1-voters-pass-bond-authorization-in-november-2019-election">Read More</Link>
                  </div>
                </div>

                <div className="news-preview-item">
                  <Link to="/2019-bond-election/2019-10-28-westlake-mud-1-holds-educational-open-house-regarding-november-5-election">
                    <Img fluid={communitythree.fluid} alt="" style={{ height: '154px', borderTopLeftRadius: '2px', borderTopRightRadius: '2px' }} />
                  </Link>
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <Link to="/2019-bond-election/2019-10-28-westlake-mud-1-holds-educational-open-house-regarding-november-5-election">Westlake MUD 1 holds educational open house regarding November 5 election</Link>
                    <br /><small className="accent-color">October 28, 2019</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <Link className="button is-white" to="/2019-bond-election/2019-10-28-westlake-mud-1-holds-educational-open-house-regarding-november-5-election">Read More</Link>
                  </div>
                </div>

                <div className="news-preview-item">
                  <Link to="/2019-bond-election/funding-district-rehabilitation">
                    <Img fluid={payasyougo.fluid} alt="" style={{ height: '154px', borderTopLeftRadius: '2px', borderTopRightRadius: '2px' }} />
                  </Link>
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <Link to="/2019-bond-election/funding-district-rehabilitation">Funding District Rehabilitation: Selling Bonds vs. Direct Billing</Link>
                    <br /><small className="accent-color">October 25, 2019</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <Link className="button is-white" to="/2019-bond-election/funding-district-rehabilitation">Read More</Link>
                  </div>
                </div>

                <div className="news-preview-item">
                  <Link to="/2019-bond-election/2019-10-04-resident-awareness-open-house-save-the-date">
                    <Img fluid={savethedate.fluid} alt="" style={{ height: '154px', borderTopLeftRadius: '2px', borderTopRightRadius: '2px' }} />
                  </Link>
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <Link to="/2019-bond-election/2019-10-04-resident-awareness-open-house-save-the-date">Resident Awareness Open House (Save the Date)</Link>
                    <br /><small className="accent-color">October 4, 2019</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <Link className="button is-white" to="/2019-bond-election/2019-10-04-resident-awareness-open-house-save-the-date">Read More</Link>
                  </div>
                </div>

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <Link to="/2019-election-faq">Bond Authorization Election FAQ</Link>
                    <br /><small className="accent-color">September 27, 2019</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <Link className="button is-white" to="/2019-election-faq">Read More</Link>
                  </div>
                </div>

                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/Westlake-MUD-1-Sample-Ballot-2019.pdf">Sample Ballot</a>
                    <br /><small className="accent-color">October 12, 2019</small></p>
                    <small><em>The text "levy of taxes, without limit as to rate or amount" on the ballot simply means that the District can make good on its promise to bond buyers that it can levy the required tax rate, depending on property values, to pay the debt-service payment when due on the issued bonds. An "unlimited tax" pledge is the strongest pledge a governmental entity can make and will allow the District to borrow at the lowest possible interest rate.</em></small>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/Westlake-MUD-1-Sample-Ballot-2019.pdf">View</a>
                  </div>
                </div>

              </div>
              <div className="section">
                <hr />
              <p><strong>English</strong><br />
              If you need this election document provided in Chinese, Vietnamese, or Spanish, please send us a message requesting the translated document using the <Link to="/contact">“Contact Us”</Link> link on this website.</p>

              <p><strong>Spanish</strong><br />
              Si necesita que le entreguen este documento sobre la elección en chino, vietnamita o español, envíenos un mensaje solicitando el documento traducido a través del enlace <Link to="/contact">“Contact Us”</Link> de este sitio web.</p>

              <p><strong>Vietnamese</strong><br />
              Nếu quý vị cần tài liệu bầu cử này bằng tiếng Hoa, tiếng Việt, hoặc tiếng Tây ban nha, vui lòng gửi thư yêu cầu cung cấp bản chuyển ngữ bằng cách sử dụng đường liên kết <Link to="/contact">"Contact Us"</Link> trên website này.</p>

              <p><strong>Chinese</strong><br />
              如果您需要這份選舉文件的中文、越南語或西班牙語版本，請使用本網站上的<Link to="/contact">「Contact Us」</Link>鏈接，向我們索取翻譯文本。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
}
}

export default Election2019Page

export const election2019PageQuery = graphql`
  query Election2019Query {
    payasyougo: imageSharp(original: {src: {regex: "/bond-financing-vs-pay-as-you-go-ENG/"}}) {
      fluid (maxWidth: 400){
        ...GatsbyImageSharpFluid
      }
    }
    voterspass: imageSharp(original: {src: {regex: "/DSC_2977/"}}) {
      fluid (maxWidth: 400){
        ...GatsbyImageSharpFluid
      }
    }
    communitythree: imageSharp(original: {src: {regex: "/community3/"}}) {
      fluid (maxWidth: 400){
        ...GatsbyImageSharpFluid
      }
    }
    savethedate: imageSharp(original: {src: {regex: "/save-the-date-oct-15/"}}) {
      fluid (maxWidth: 400){
        ...GatsbyImageSharpFluid
      }
    }
  }
`